import { graphql, useStaticQuery } from 'gatsby';

import History from 'components/history';
import { EducationHistoryQuery } from 'typings/graphql';

const query = graphql`
  query EducationHistory {
    education: allMdx(filter: { fields: { category: { eq: "education" } } }) {
      ...HistoryItem
    }
  }
`;

type Props = {};

const EducationHistory: React.FC<Props> = () => {
  const data = useStaticQuery<EducationHistoryQuery>(query);

  return <History data={data.education} />;
};

export default EducationHistory;
