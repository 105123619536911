import styled, { css } from 'styled-components';

import Icon from 'components/icon';
import media from 'styles/media';

type Props = {
  active?: boolean;
  disabled?: boolean;
  right?: boolean;
};

const BarButton = styled(Icon).attrs<Props>((props) => ({
  role: 'button',
  ...props,
}))<Props>`
  height: 15px;
  margin-left: auto;
  pointer-events: all;
  font-size: 14px;
  padding: 30px;

  ${(props) => css`
    ${props.disabled &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

    ${media.medium`
      transform: ${props.right ? 'translateX(-80px)' : 'translateX(70px)'};
      transition: transform var(--tr-md);

      ${
        props.active &&
        css`
          transform: translateX(0);
        `
      }
    `}
  `}
`;

export default BarButton;
