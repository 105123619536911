import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import Link from 'components/link';
import useLocation from 'hooks/useLocation';
import { useLocalization } from 'i18n';

const LanguageLink = styled(Link)``;

const LanguageItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  box-shadow: inset var(--shadow-1);
  background: var(--content-color);
  font-size: 12px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: var(--main-color);
      box-shadow: var(--shadow-1);

      ${LanguageLink} {
        color: var(--content-color);
      }
    `}
`;

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

type Props = {
  className?: string;
};

const Languages: React.FC<Props> = ({ className }) => {
  const { locale, locales } = useLocalization();
  const location = useLocation();
  const pathname = useMemo(
    () => location.pathname.replace(`/${locale}/`, '/') + location.search,
    [location.pathname, location.search, locale],
  );

  if (locales?.length < 2) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {locales?.map((language) => (
        <LanguageItem key={language} active={language === locale}>
          <LanguageLink to={pathname} language={language}>
            {language}
          </LanguageLink>
        </LanguageItem>
      ))}
    </Wrapper>
  );
};

export default Languages;
