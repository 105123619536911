import { useEffect, useLayoutEffect, useState } from 'react';

import { Breakpoints, QUERIES } from 'styles/media';

type Effect = typeof useEffect | typeof useLayoutEffect;

const createUseMedia =
  (effect: Effect) =>
  (breakpoint: Breakpoints, defaultState = false) => {
    const [state, setState] = useState(defaultState);
    const query = QUERIES[breakpoint];

    effect(() => {
      let mounted = true;
      const mediaQueryList = window.matchMedia(query);

      const handleChange = () => {
        if (!mounted) {
          return;
        }

        setState(Boolean(mediaQueryList.matches));
      };

      mediaQueryList.addEventListener('change', handleChange);
      setState(mediaQueryList.matches);

      return () => {
        mounted = false;
        mediaQueryList.addEventListener('change', handleChange);
      };
    }, [query]);

    return state;
  };

export const useMedia = createUseMedia(useEffect);
export const useMediaLayout = createUseMedia(useLayoutEffect);

export default useMedia;
