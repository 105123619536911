import styled from 'styled-components';

import Flex from 'components/flex';

const SectionTitle = styled(Flex).attrs((props) => ({
  as: 'h4',
  ...props,
}))`
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 32px;
`;

export default SectionTitle;
