import { createElement } from 'react';

import getDisplayName from 'utils/getDisplayName';

function withProps<Component extends React.ElementType, Props extends React.ComponentProps<Component>>(
  component: Component,
  defaultProps: Partial<Props>,
) {
  const render: React.FC<Props> = (props) =>
    createElement<Props>(component, {
      ...defaultProps,
      ...props,
    });

  render.displayName = `withProps(${getDisplayName(component)})`;

  return render;
}

export default withProps;
