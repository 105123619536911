import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';

import BarButton from 'components/barButton';
import Box from 'components/box';
import Divider from 'components/divider';
import Flex from 'components/flex';
import Link from 'components/link';
import List, { ListItem } from 'components/list';
import Progress from 'components/progress';
import Scrollable from 'components/scrollable';
import Table, { TableCell, TableRow } from 'components/table';
import Me from 'containers/me';
import Social from 'containers/social';
import { useTranslation } from 'i18n';
import media from 'styles/media';
import { LeftBarQuery } from 'typings/graphql';

import { diffDateFromNow } from 'utils/date';

const BAR_WIDTH = '290px';

const Wrapper = styled(Box)<{ active?: boolean }>`
  background: var(--bar-1-color);
  width: ${BAR_WIDTH};
  min-width: ${BAR_WIDTH};
  position: relative;
  z-index: 99;
  box-shadow: var(--shadow-1);
  transition: transform var(--tr-lg);

  ${(props) => css`
    ${media.medium`
      padding: 0px;
      position: absolute;
      left: -${BAR_WIDTH};

      ${
        props.active &&
        css`
          transform: translateX(${BAR_WIDTH});
        `
      }
    `}
  `}
`;

const Frame = styled(Box)`
  padding: 0 16px;
`;

const Header = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  padding: 32px;
  height: 235px;
  background: var(--bar-2-color-light);
  text-align: center;
  box-shadow: var(--shadow-2);
  box-sizing: border-box;
`;

const StyledBarButton = styled(BarButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
`;

const Content = styled(Scrollable)`
  padding: 240px 0 50px;
`;

const Footer = styled(Flex)`
  align-items: center;
  height: 50px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 36px;
  background: var(--bar-2-color-light);
  box-shadow: var(--shadow-2);
  box-sizing: border-box;
  z-index: 999;
`;

const query = graphql`
  query LeftBar {
    me {
      dateOfBirth
      country
      city
    }

    skills {
      languages {
        key
        value
      }

      programmingLanguages {
        key
        value
      }

      toolsGroups
    }

    cv: file(name: { eq: "cv" }) {
      publicURL
    }
  }
`;

export type LeftBarProps = {
  active?: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler;
};

const LeftBar: React.FC<LeftBarProps> = ({ active, disabled, onClick }) => {
  const { t } = useTranslation();
  const data = useStaticQuery<LeftBarQuery>(query);
  const age = useMemo(() => diffDateFromNow(data.me.dateOfBirth, 'years'), [data.me.dateOfBirth]);

  return (
    <Wrapper active={active}>
      <Frame>
        <StyledBarButton active={active} disabled={disabled} onClick={onClick} name="ellipsis-v" aria-label={t('Left menu')} />

        <Header>
          <Me onAvatarClick={onClick} />
        </Header>
        <Content m={4}>
          <Table>
            <TableRow>
              <TableCell>
                <h6>{t('Residence')}:</h6>
              </TableCell>
              <TableCell>{t(data.me.country)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h6>{t('City')}:</h6>
              </TableCell>
              <TableCell>{t(data.me.city)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h6>{t('Age')}:</h6>
              </TableCell>
              <TableCell>{age}</TableCell>
            </TableRow>
          </Table>

          <Divider my={4} />

          <Flex justifyContent="space-between">
            {data.skills.languages.map((language) => (
              <Flex key={language.key} flexDirection="column" alignItems="center">
                <Progress m={2} value={language.value} />
                <h6>{t(language.key)}</h6>
              </Flex>
            ))}
          </Flex>

          <Divider my={4} />

          <Flex flexDirection="column">
            {data.skills.programmingLanguages.map((programmingLanguage) => (
              <Flex key={programmingLanguage.key} flexDirection="column" my={2}>
                <h6>{programmingLanguage.key}</h6>
                <Progress value={programmingLanguage.value} mt={2} type="line" />
              </Flex>
            ))}
          </Flex>

          <Divider my={4} />

          <Flex flexDirection="column">
            <List>
              {data.skills.toolsGroups.map((tools) => (
                <ListItem key={tools[0]}>{tools.join(', ')}</ListItem>
              ))}
            </List>
          </Flex>

          <Divider my={4} />

          <Flex>
            <Link to={data.cv.publicURL} icon="download" download>
              {t('Download CV')}
            </Link>
          </Flex>
        </Content>
        <Footer>
          <Social />
        </Footer>
      </Frame>
    </Wrapper>
  );
};

export default LeftBar;
