import { useCallback, useState } from 'react';
import { PageProps } from 'gatsby';
import styled, { css } from 'styled-components';

import Box from 'components/box';
import Flex from 'components/flex';
import Scrollable from 'components/scrollable';
import Footer from 'containers/footer';
import LeftBar from 'containers/leftBar';
import RightBar from 'containers/rightBar';
import TopBar from 'containers/topBar';
import useMedia from 'hooks/useMedia';
import media from 'styles/media';

const App = styled(Box)`
  padding: 16px;
  background: var(--deep-color);
  width: 100vw;
  height: calc(var(--vh) * 100);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  ${media.medium`
    padding: 0;
  `}
`;

const Wrapper = styled(Box)`
  background: var(--content-color);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-1);

  ${media.medium`
    border-radius: 0;
    height: calc(var(--vh) * 100);
  `}
`;

const Container = styled(Flex)`
  position: relative;
  flex-wrap: nowrap;

  ${media.medium`
    width: 100%;
  `}
`;

const Content = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(calc(var(--vh) * 100) - 32px);
  padding-right: 80px;

  ${media.medium`
    position: relative;
    width: 100vw;
    height: calc(var(--vh) * 100);
    padding-right: 0;
    padding-top: 72px;
  `}
`;

const Curtain = styled(Box)<{ active?: boolean }>`
  background: var(--content-color-s-light);
  position: absolute;
  z-index: 9;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--tr-lg);

  ${(props) => css`
    ${props.active &&
    css`
      pointer-events: all;
      opacity: 1;
    `}
  `}
`;

type Props = {
  withoutLeftbar?: boolean;
  withoutRightbar?: boolean;
} & PageProps;

const MainLayout: React.FC<Props> = ({ children, withoutLeftbar, withoutRightbar, pageContext }) => {
  const isLargeScreen = useMedia('large');
  const [isLeftBarActive, setIsLeftBarActive] = useState(false);
  const [isRightBarActive, setIsRightBarActive] = useState(false);

  const handleLeftBarClick = useCallback(() => {
    setIsLeftBarActive((isLeftBarActive) => !isLeftBarActive);
  }, [setIsLeftBarActive]);

  const handleRightBarClick = useCallback(() => {
    setIsRightBarActive((isRightBarActive) => !isRightBarActive);
  }, [setIsRightBarActive]);

  const handleCurtainClick = useCallback(() => {
    setIsLeftBarActive(false);
    setIsRightBarActive(false);
  }, [setIsLeftBarActive, setIsRightBarActive]);

  return (
    <App>
      <TopBar />
      <Wrapper>
        <Container>
          {!withoutLeftbar && <LeftBar active={isLeftBarActive} disabled={isRightBarActive} onClick={handleLeftBarClick} />}
          <Content>
            <Curtain active={isLeftBarActive || isRightBarActive} onClick={handleCurtainClick} />
            <Scrollable>
              <Box mb={13}>{children}</Box>
              <Footer />
            </Scrollable>
          </Content>
          {!withoutRightbar && (
            <RightBar
              active={isRightBarActive}
              disabled={isLeftBarActive && !isLargeScreen}
              onClick={handleRightBarClick}
              pageContext={pageContext}
            />
          )}
        </Container>
      </Wrapper>
    </App>
  );
};

export default MainLayout;
