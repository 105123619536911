import styled, { CSSProperties } from 'styled-components';

import Box from 'components/box';

export type FlexProps = {
  inline?: boolean;
  children?: React.ReactNode;
} & Pick<
  CSSProperties,
  | 'flex'
  | 'flexDirection'
  | 'flexFlow'
  | 'flexWrap'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexBasis'

  // justify
  | 'justifyContent'
  | 'justifyItems'
  | 'justifySelf'

  // align
  | 'alignContent'
  | 'alignItems'
  | 'alignSelf'

  // other
  | 'order'
>;

const Flex = styled(Box)<FlexProps>(({ inline, children, ...props }: FlexProps) => ({
  ...props,
  display: inline ? 'inline-flex' : 'flex',
}));

export default Flex;
