import { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import Box from 'components/box';
import Flex from 'components/flex';
import Link from 'components/link';
import Text from 'components/text';
import { useLocalization, useTranslation } from 'i18n';
import media from 'styles/media';

import { formatDate } from 'utils/date';

const Mark = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 3px var(--main-color);
  background: var(--content-color);
  box-shadow: var(--shadow-1);
  box-sizing: border-box;
`;

const MarkLight = styled.div`
  background: var(--text-color-1);
  position: absolute;
  top: 4px;
  right: -4px;
  width: 23px;
  height: 23px;
  opacity: 0;
  border-radius: 50%;
  transition: transform var(--tr-md), opacity var(--tr-md);
  box-sizing: border-box;
`;

const puls = keyframes`
  0% {
    transform: scale(.6);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const Wrapper = styled(Box)`
  position: relative;

  :hover {
    ${MarkLight} {
      animation: ${puls} 1s infinite;
    }
  }
`;

const Content = styled(Box)`
  position: relative;
  margin-right: 45px;
  background: var(--card-color);
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: var(--shadow-1);
  overflow: hidden;

  &:after {
    height: 10px;
    width: 10px;
    background-color: rgba(43, 43, 53, 0.98);
    transform: rotate(-135deg);
    content: '';
    position: absolute;
    top: 11px;
    right: -5px;
    border-top-right-radius: 50%;
  }
`;

const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  ${media.small`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const Date = styled(Box)`
  color: var(--text-color-2);
  margin-left: auto;
  background: var(--bar-1-color);
  padding: 5px 15px;
  border-radius: 15px;
  box-shadow: inset var(--shadow-1);
  font-size: 10px;
  text-transform: lowercase;
  white-space: nowrap;

  ${media.small`
    margin-top: 8px;
  `}
`;

export type TimelineItemProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  dateFrom: string;
  dateTo?: string;
  link?: string;
  linkText?: string;
};

const DATE_FORMAT = 'MMM YYYY';

const TimelineItem: React.FC<TimelineItemProps> = ({ title, subtitle, children, dateFrom, dateTo, link, linkText }) => {
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const from = useMemo(() => formatDate(dateFrom, DATE_FORMAT, locale), [dateFrom, locale]);
  const to = useMemo(() => (dateTo ? formatDate(dateTo, DATE_FORMAT, locale) : t('now')), [dateTo, locale, t]);

  return (
    <Wrapper>
      <Mark />
      <MarkLight />
      <Content>
        <Header mb={4}>
          <Box mr={4}>
            <h5>{title}</h5>
            <Text mt={1} italic>
              {subtitle}
            </Text>
          </Box>
          <Box>
            <Date>
              {from} - {to}
            </Date>
          </Box>
        </Header>
        <Box>{children}</Box>
        {link && linkText && (
          <Link to={link} colored withChevron>
            {linkText}
          </Link>
        )}
      </Content>
    </Wrapper>
  );
};

export default TimelineItem;
