import styled from 'styled-components';

import Box from 'components/box';
import media from 'styles/media';

const Fluid = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  ${media.medium`
    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;
  `}
`;

export default Fluid;
