import styled, { CSSProperties } from 'styled-components';

const normalizeOffset = (offset: number | string) => {
  if (typeof offset === 'number') {
    return `${offset * 4}px`;
  }

  return offset;
};

export type BoxProps = {
  children?: React.ReactNode;

  inline?: boolean;

  p?: number | CSSProperties['padding'];
  px?: number | (CSSProperties['paddingTop'] & CSSProperties['paddingBottom']);
  py?: number | (CSSProperties['paddingLeft'] & CSSProperties['paddingRight']);
  pt?: number | CSSProperties['paddingTop'];
  pb?: number | CSSProperties['paddingBottom'];
  pl?: number | CSSProperties['paddingLeft'];
  pr?: number | CSSProperties['paddingRight'];

  m?: number | CSSProperties['margin'];
  mx?: number | (CSSProperties['marginTop'] & CSSProperties['marginBottom']);
  my?: number | (CSSProperties['marginLeft'] & CSSProperties['marginRight']);
  mt?: number | CSSProperties['marginTop'];
  mb?: number | CSSProperties['marginBottom'];
  ml?: number | CSSProperties['marginLeft'];
  mr?: number | CSSProperties['marginRight'];
};

// eslint-disable-next-line complexity
const Box = styled.div<BoxProps>(({ inline, children, ...props }: BoxProps) => ({
  display: inline ? 'inline-block' : 'block',

  ...(props.p ? { padding: normalizeOffset(props.p) } : {}),
  ...(props.py ? { paddingTop: normalizeOffset(props.py), paddingBottom: normalizeOffset(props.py) } : {}),
  ...(props.px ? { paddingLeft: normalizeOffset(props.px), paddingRight: normalizeOffset(props.px) } : {}),
  ...(props.pt ? { paddingTop: normalizeOffset(props.pt) } : {}),
  ...(props.pb ? { paddingBottom: normalizeOffset(props.pb) } : {}),
  ...(props.pl ? { paddingLeft: normalizeOffset(props.pl) } : {}),
  ...(props.pr ? { paddingRight: normalizeOffset(props.pr) } : {}),

  ...(props.m ? { margin: normalizeOffset(props.m) } : {}),
  ...(props.my ? { marginTop: normalizeOffset(props.my), marginBottom: normalizeOffset(props.my) } : {}),
  ...(props.mx ? { marginLeft: normalizeOffset(props.mx), marginRight: normalizeOffset(props.mx) } : {}),
  ...(props.mt ? { marginTop: normalizeOffset(props.mt) } : {}),
  ...(props.mb ? { marginBottom: normalizeOffset(props.mb) } : {}),
  ...(props.ml ? { marginLeft: normalizeOffset(props.ml) } : {}),
  ...(props.mr ? { marginRight: normalizeOffset(props.mr) } : {}),
}));

export default Box;
