import styled from 'styled-components';

import Box from 'components/box';

const Divider = styled(Box)`
  background: var(--text-color-3);
  width: 100%;
  height: 1px;
  opacity: 0.3;
`;

export default Divider;
