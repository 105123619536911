import styled from 'styled-components';

import Box from 'components/box';

const Wrapper = styled(Box)`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 70px;
  background: var(--bar-1-color);
  box-shadow: var(--shadow-1);
  padding: 0 32px;
  display: none;
  justify-content: space-between;
  align-items: center;

  @media (max-width: var(--bp-md)) {
    display: flex;
  }
`;

type Props = {};

const TopBar = (props: Props) => <Wrapper />;

export default TopBar;
