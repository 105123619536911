import { css } from 'styled-components';

export const BREAKPOINTS = {
  small: 767,
  medium: 920,
  large: 1400,
};

export type Breakpoints = keyof typeof BREAKPOINTS | 'print';

export const QUERIES: Record<Breakpoints, string> = {
  small: `(max-width: ${BREAKPOINTS.small}px)`,
  medium: `(max-width: ${BREAKPOINTS.medium}px)`,
  large: `(min-width: ${BREAKPOINTS.large}px)`,
  print: `print`,
};

type Params = Parameters<typeof css>;

const mediaQuery =
  (...query: Params) =>
  (...rules: Params) =>
    css`
      @media ${css(...query)} {
        ${css(...rules)}
      }
    `;

export default {
  small: mediaQuery`${QUERIES.small}`,
  medium: mediaQuery`${QUERIES.medium}`,
  large: mediaQuery`${QUERIES.large}`,
  print: mediaQuery`${QUERIES.print}`,
};
