import styled, { css } from 'styled-components';

import Box from 'components/box';

type Props = {
  active?: Boolean;
};

const Burger = styled(Box).attrs((props) => ({
  as: 'button',
  ...props,
  children: <span />,
}))<Props>`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  background: transparent;

  :hover {
    span,
    span:after,
    span:before {
      background: var(--text-color-1);
    }
  }

  span,
  span:after,
  span:before {
    content: '';
    display: block;
    width: 15px;
    height: 3px;
    border-radius: 1px;
    background: var(--text-color-2);
    transition: transform var(--tr-md);
    backface-visibility: hidden;
  }

  span {
    position: relative;

    :after,
    :before {
      position: absolute;
    }

    :before {
      top: -5px;
    }

    :after {
      top: 5px;
    }
  }

  ${(props) =>
    props.active &&
    css`
      span {
        transform: rotate(45deg);

        :before {
          transform: translate(0px, 5px) rotate(-90deg);
        }
        :after {
          transform: translate(0px, -5px) rotate(-90deg);
        }
      }
    `}
`;

export default Burger;
