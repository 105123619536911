import styled from 'styled-components';

import Box from 'components/box';
import Flex from 'components/flex';

const TableCell = styled(Box)``;

const TableRow = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  :last-child {
    margin-bottom: 0;
  }
`;

const Table = styled(Box)`
  color: var(--text-color-2);
`;

export default Table;
export { TableRow, TableCell };
