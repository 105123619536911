import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import Box from 'components/box';
import Flex from 'components/flex';
import Image, { ImageProps } from 'components/image';
import { useTranslation } from 'i18n';

const Wrapper = styled(Box)`
  width: 90px;
  height: 90px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  margin-bottom: 16px;
`;

const Curtain = styled(Flex)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  justify-content: center;
  outline: inherit;
  z-index: 0;

  ${Image} {
    position: absolute;
    border-radius: 50%;
    z-index: 0;
  }
`;

const Dot = styled.i`
  position: absolute;
  bottom: 3px;
  right: 5px;
  height: 15px;
  width: 15px;
  background: var(--main-color);
  border-radius: 50%;
  z-index: 0;
  transition: transform var(--tr-md);

  :after {
    position: relative;
    text-transform: none;
    content: attr(data-title);
    width: 115px;
    display: block;
    padding: 6px 12px;
    top: -10px;
    left: 28px;
    font-size: 10px;
    font-weight: 200;

    color: var(--text-color-2);
    box-shadow: var(--shadow-2);
    background: var(--deep-color);
    opacity: 0;
    pointer-events: none;
    transform: translateX(20px);
    transition: transform var(--tr-md);
    text-align: left;
  }

  :before {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    right: -15px;
    top: 5px;
    opacity: 0;
    background: var(--deep-color);
    pointer-events: none;
    transform: translateX(20px) rotate(45deg);
    transition: transform var(--tr-md);
    z-index: 99;
  }

  :hover {
    transform: scale(1);

    :after {
      opacity: 1;
      transform: translateX(0);
    }

    :before {
      opacity: 1;
      transform: translateX(0) rotate(45deg);
    }
  }
`;

const Lamp = styled(Box)<{ isAvailable?: boolean }>`
  z-index: 2;

  :before {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 1px;
    background: var(--text-color-1);
    height: 23px;
    width: 23px;
    border-radius: 50%;
    animation: puls 1s infinite;
    @keyframes puls {
      0% {
        transform: scale(0.6);
        opacity: 1;
      }

      100% {
        transform: scale(1);
        opacity: 0;
      }
    }
  }

  ${(props) => css`
    ${!props.isAvailable &&
    css`
      :before {
        opacity: 0;
        animation: none;
      }

      ${Dot} {
        background: red;
      }
    `}
  `}
`;

export type Props = {
  image: ImageProps;
  isAvailable?: boolean;
};

const Avatar = ({ image, isAvailable }: Props) => {
  const { t } = useTranslation();
  const title = useMemo(() => (isAvailable ? t("I'm available for freelance hire") : t("Sorry I'm busy today")), [isAvailable, t]);

  return (
    <Wrapper>
      <Curtain>
        <Image {...image} />
        <Lamp isAvailable={isAvailable}>
          <Dot data-title={title} />
        </Lamp>
      </Curtain>
    </Wrapper>
  );
};

export default Avatar;
