import { PageProps } from 'gatsby';

import Fluid from 'components/fluid';
import SEO from 'components/seo';
import { useTranslation } from 'i18n';

const SpeechesPage: React.FC<PageProps> = ({ path }) => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t('Speeches page')} path={path} />

      <Fluid />
    </>
  );
};

export default SpeechesPage;
