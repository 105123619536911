import 'styles/variables.css';
import 'styles/global.css';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line
const FONTS_HREF = `https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap`;

const Global = () => {
  useEffect(() => {
    const updateProperties = () => {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateProperties();

    window.addEventListener('resize', updateProperties);

    return () => {
      window.removeEventListener('resize', updateProperties);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link rel="preconnect dns-prefetch" href="https://fonts.gstatic.com" />
        <link rel="preconnect dns-prefetch" href="https://www.googletagmanager.com" />

        <link href={FONTS_HREF} rel="preload" as="style" />
        <link href={FONTS_HREF} rel="stylesheet" />
      </Helmet>
    </>
  );
};

export default Global;
