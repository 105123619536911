import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Flex from 'components/flex';
import Icon, { IconProps } from 'components/icon';
import { SocialQuery } from 'typings/graphql';

const Wrapper = styled(Flex)`
  flex: 1;
  justify-content: space-between;
`;

const SocialLink = styled.a`
  color: var(--text-color-2);
  transition: color var(--tr-sm);
  font-size: 20px;

  :hover {
    text-shadow: var(--text-neon);
    color: var(--text-color-1);
  }
`;

const query = graphql`
  query Social {
    allSocial {
      nodes {
        id
        name
        icon
        url
      }
    }
  }
`;

const Social = () => {
  const data = useStaticQuery<SocialQuery>(query);

  return (
    <Wrapper>
      {data.allSocial.nodes.map(({ id, name, icon, url }) => (
        <SocialLink key={id} href={url} aria-label={name} target="_blank" rel="noopener">
          <Icon icon={['fab', (icon || name) as IconProps['name']]} />
        </SocialLink>
      ))}
    </Wrapper>
  );
};

export default Social;
