import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Avatar from 'components/avatar';
import Box from 'components/box';
import Link from 'components/link';
import Text from 'components/text';
import { useTranslation } from 'i18n';
import { MeQuery } from 'typings/graphql';

const Wrapper = styled(Box)``;

const Name = styled(Text)`
  color: var(--text-color-1);
`;

const Position = styled(Text)`
  text-transform: capitalize;
`;

const query = graphql`
  query Me {
    me {
      name
      position
      company
      canBeHired
    }
    avatar: file(name: { eq: "avatar" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 90, height: 90)
      }
    }
  }
`;

type Props = {
  onAvatarClick?: React.MouseEventHandler;
};

const Me: React.FC<Props> = ({ onAvatarClick }) => {
  const { t } = useTranslation();
  const data = useStaticQuery<MeQuery>(query);

  const { me, avatar } = data;

  return (
    <Wrapper>
      <Link to="/" onClick={onAvatarClick} aria-label={me.name}>
        <Avatar isAvailable={me.canBeHired} image={{ file: avatar, alt: 'avatar' }} />
      </Link>
      <Name mb={3} as="h5">
        {me.name}
      </Name>
      <Position>
        {t(me.position)}
        <br />
        {me.company}
      </Position>
    </Wrapper>
  );
};

export default Me;
