import '@fortawesome/fontawesome-svg-core';

import { IconName } from '@fortawesome/fontawesome-common-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub, faGoogle, faLinkedinIn, faTelegramPlane, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faChevronLeft, faChevronRight, faDownload, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import Box, { BoxProps } from 'components/box';

const fas = [faCheck, faChevronLeft, faChevronRight, faDownload, faEllipsisV];
const fab = [faGithub, faGoogle, faLinkedinIn, faTelegramPlane, faTwitter];

export const icons = [...fas, ...fab];

library.add(...icons);

export type IconProps = {
  name?: IconName;
} & Partial<FontAwesomeIconProps>;

const Icon = ({ name, ...props }: IconProps & BoxProps) => <Box as={FontAwesomeIcon} icon={name} {...props} />;

export default Icon;
