import { graphql, useStaticQuery } from 'gatsby';

import History from 'components/history';
import { CompaniesHistoryQuery } from 'typings/graphql';

const query = graphql`
  query CompaniesHistory {
    companies: allMdx(filter: { fields: { category: { eq: "companies" } } }) {
      ...HistoryItem
    }
  }
`;

type Props = {};

const CompaniesHistory: React.FC<Props> = () => {
  const data = useStaticQuery<CompaniesHistoryQuery>(query);

  return <History data={data.companies} />;
};

export default CompaniesHistory;
