import { useMemo } from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import { useLocalization } from 'i18n';
import { SeoQuery } from 'typings/graphql';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl
        description
        social {
          twitter
        }
      }
    }
  }
`;

type MetaProps = JSX.IntrinsicElements['meta'];

type Props = {
  title: string;
  path: string;
  description?: string;
  image?: {
    src: string;
    width: number;
    height: number;
  };
  meta?: MetaProps[];
  keywords?: string[];
};

const SEO: React.FC<Props> = ({ title, path, description, image, meta, keywords }) => {
  const { locale } = useLocalization();
  const data = useStaticQuery<SeoQuery>(query);

  const metaDescription = description || data.site.siteMetadata.description;
  const metaImage = image?.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null;
  const metaUrl = `${data.site.siteMetadata.siteUrl}${path}`;
  const metaMetas = useMemo<MetaProps[]>(
    () => [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:url`,
        content: metaUrl,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:creator`,
        content: `@${data.site.siteMetadata.social.twitter}`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },

      ...(metaImage
        ? [
            {
              property: `og:image`,
              content: metaImage,
            },
            {
              property: `og:image:alt`,
              content: title,
            },
            {
              property: 'og:image:width',
              content: image.width,
            },
            {
              property: 'og:image:height',
              content: image.height,
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
          ]
        : [
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ]),
      ...(keywords && keywords.length > 0
        ? [
            {
              name: `keywords`,
              content: keywords.join(`, `),
            },
          ]
        : []),
      ...meta,
    ],
    [data, title, metaDescription, metaUrl, metaImage, image, keywords, meta],
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={metaMetas}
    />
  );
};

SEO.defaultProps = {
  meta: [],
  keywords: [],
  path: ``,
};

export default SEO;
