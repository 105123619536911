import { PageProps } from 'gatsby';
import styled, { css } from 'styled-components';

import BarButton from 'components/barButton';
import Box from 'components/box';
import Burger from 'components/burger';
import Flex from 'components/flex';
import Scrollable from 'components/scrollable';
import Languages from 'containers/languages';
import MainMenu from 'containers/mainMenu';
import { useTranslation } from 'i18n';
import media from 'styles/media';

const BAR_WIDTH = '230px';

const Wrapper = styled(Box)<{ active?: boolean }>`
  background: var(--bar-1-color);
  width: ${BAR_WIDTH};
  min-width: ${BAR_WIDTH};
  position: absolute;
  z-index: 99;
  box-shadow: var(--shadow-1);
  transition: transform var(--tr-lg);
  right: -150px;

  ${(props) => css`
    ${props.active &&
    css`
      transform: translateX(-150px);
    `}

    ${media.medium`
      padding: 0px;
      position: absolute;
      right: -${BAR_WIDTH};

      ${
        props.active &&
        css`
          transform: translateX(-${BAR_WIDTH});
        `
      }
    `}
  `}
`;

const Frame = styled(Box)`
  position: relative;
`;

const Header = styled(Flex)`
  width: 100%;
  height: 70px;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  box-shadow: var(--shadow-2);
  background: var(--bar-2-color-light);
`;

const Content = styled(Scrollable)`
  height: 100%;
  padding: 75px 0 50px;
  box-sizing: border-box;
`;

const Footer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--bar-2-color-light);
  box-shadow: var(--shadow-2);
  padding: 30px 26px;
  margin: 0;
`;

export type RightBarProps = {
  active?: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler;
} & Pick<PageProps, 'pageContext'>;

const RightBar: React.FC<RightBarProps> = ({ active, disabled, onClick, pageContext }) => {
  const { t } = useTranslation();

  return (
    <Wrapper active={active}>
      <Frame>
        <Header>
          <BarButton active={active} disabled={disabled} onClick={onClick} as={Burger} aria-label={t('Right menu')} right />
        </Header>
        <Content>
          <MainMenu active={active} activePath={pageContext['originalPath']} onMenuItemClick={onClick} />
        </Content>
        <Footer>
          <Languages />
        </Footer>
      </Frame>
    </Wrapper>
  );
};

export default RightBar;
