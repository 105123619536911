import Global from '../src/global';

export function wrapRootElement({ element }) {
  return (
    <>
      <Global />
      {element}
    </>
  );
}
