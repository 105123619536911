import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import Box from 'components/box';
import { ImageSharp } from 'typings/graphql';

type ImageFileNode = { childImageSharp?: Pick<ImageSharp, 'gatsbyImageData'> };

export type ImageProps = React.ComponentProps<typeof Image> & { file?: ImageFileNode };

const Image = styled(Box).attrs(({ file, ...props }: { file?: ImageFileNode }) => {
  // @ts-expect-error
  const image = file && getImage(file);

  return {
    as: GatsbyImage,
    image,

    alt: '',
    objectFit: 'cover',
    objectPosition: 'center',
    width: image.width,
    height: image.height,

    ...props,
  };
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  border-style: none;

  img {
    width: ${(props) => `${props.width}px` || '100%'} !important;
    height: ${(props) => `${props.height}px` || '100%'} !important;
  }
`;

export default Image;
