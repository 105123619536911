import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import styled, { css } from 'styled-components';

import Box, { BoxProps } from 'components/box';
import Icon, { IconProps } from 'components/icon';

type StyledLinkProps = {
  white?: boolean;
  colored?: boolean;
  left?: boolean;
  withChevron?: boolean;
  icon: IconProps['name'];
};

const StyledIcon = styled(Icon)`
  position: absolute;
  display: inline-block;
  top: 3px;
  margin-left: 4px;
  font-size: 8px;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  transition: transform var(--tr-md);
`;

const StyledLink = styled(Box).attrs((props) => ({
  as: 'a',
  ...props,
}))<StyledLinkProps>`
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 10px;
  display: inline-block;
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-right: 16px;
  margin-bottom: 12px;
  color: var(--text-color-2);
  transition: transform var(--tr-md), color var(--tr-md);

  :hover {
    color: var(--text-color-1);
    text-shadow: var(--text-neon);
    text-decoration: none;
  }

  :focus {
    outline: inherit;
  }

  :last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  ${(props) =>
    props.white &&
    css`
      color: var(--text-color-1);

      :hover {
        color: var(--text-color-1);
        text-shadow: var(--text-neon);
        transform: translateX(5px);
      }
    `}

  ${(props) =>
    props.colored &&
    css`
      color: var(--main-color);

      :hover {
        color: var(--main-color);
        text-shadow: var(--text-neon);
        transform: translateX(5px);
      }
    `}

  ${(props) =>
    (props.icon || props.withChevron) &&
    css`
      padding-right: 12px;

      :hover {
        transform: translateX(0);

        ${StyledIcon} {
          transform: translateX(5px);
        }
      }
    `}

  ${(props) =>
    props.left &&
    css`
      padding-left: 12px;
      padding-right: 0;

      ${(props.icon || props.withChevron) &&
      css`
        ${StyledIcon} {
          left: 0;
          transform: translateX(-5px);
        }

        :hover {
          ${StyledIcon} {
            transform: translateX(-10px);
          }
        }
      `}
    `}
`;

export type LinkProps = React.ComponentProps<typeof StyledLink> & GatsbyLinkProps<any>;

const Link = ({ children, ...props }: LinkProps & BoxProps) => (
  <StyledLink {...props}>
    {children}
    {(props.icon || props.withChevron) && <StyledIcon name={props.icon || (props.left ? 'chevron-left' : 'chevron-right')} />}
  </StyledLink>
);

Link.defaultProps = {
  as: GatsbyLink,
};

export default Link;
