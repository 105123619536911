import styled from 'styled-components';

import Box from 'components/box';
import Icon, { IconProps } from 'components/icon';

type ListItemProps = {
  icon?: IconProps['name'];
};

const ListItem = styled(Box).attrs<ListItemProps>(({ icon, children, ...props }) => ({
  as: 'li',
  children: (
    <>
      <Icon name={icon} />
      {children}
    </>
  ),
  ...props,
}))<ListItemProps>`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  list-style-type: none;
  color: var(--text-color-2);

  :last-child {
    margin-bottom: 0;
  }

  svg {
    color: var(--main-color);
    margin-right: 8px;
  }
`;

ListItem.defaultProps = {
  icon: 'check',
};

const List = styled(Box).attrs((props) => ({
  as: 'ul',
  ...props,
}))`
  margin: 0;
  padding: 0;
`;

export default List;
export { ListItem };
