import styled from 'styled-components';

import Box from 'components/box';

import TimelineItem, { TimelineItemProps } from './timelineItem';

const Wrapper = styled(Box)`
  position: relative;
  height: 100%;

  ::before {
    content: '';
    position: absolute;
    top: 10px;
    right: 5px;
    height: calc(100% - 10px);
    width: 5px;
    background: var(--deep-color);
  }
`;

export type TimelineProps = {
  items: TimelineItemProps[];
} & React.ComponentProps<typeof Wrapper>;

const Timeline: React.FC<TimelineProps> = ({ items, ...props }) => {
  return (
    <Wrapper {...props}>
      {items.map((item, idx) => (
        <TimelineItem key={idx} {...item} />
      ))}
    </Wrapper>
  );
};

export default Timeline;
