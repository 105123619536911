import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Box from 'components/box';
import Flex from 'components/flex';
import { FooterQuery } from 'typings/graphql';

const Wrapper = styled(Flex).attrs((props) => ({
  as: 'footer',
  ...props,
}))`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px 30px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background: var(--bar-2-color-light);
  box-shadow: var(--shadow-2);
  color: var(--text-color-2);
  z-index: 9;
`;

const query = graphql`
  query Footer {
    site {
      siteMetadata {
        author
      }
    }
  }
`;

const Footer: React.FC = () => {
  const data = useStaticQuery<FooterQuery>(query);
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Wrapper>
      <Box>
        © {year} {data.site.siteMetadata.author}
      </Box>
    </Wrapper>
  );
};

export default Footer;
