import { useMemo } from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';

import Link, { LinkProps } from './link';

const isHash = (url: string) => /^#/.test(url);
const isInternal = (url: string) => /^\/(?!\/)/.test(url);
const isFile = (url: string) => /\..+$/.test(url);

const ExternalLink = ({ to, children, ...props }: LinkProps) => (
  <a {...props} target="_blank" rel="noopener noreferrer" href={to}>
    {children}
  </a>
);

export default ({ to, href, ...props }: LinkProps) => {
  const url = href || to;
  const as = useMemo(() => (isHash(url) || !isInternal(url) || isFile(url) ? ExternalLink : LocalizedLink), [url]);

  return <Link {...props} to={url} as={as} />;
};

export type { LinkProps };
