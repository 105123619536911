import styled from 'styled-components';

import Box from 'components/box';
import media from 'styles/media';

const Frame = styled(Box)`
  width: 100%;
  height: calc(calc(var(--vh) * 100) - 32px);
  overflow-y: auto;
  overflow-x: hidden;

  ${media.medium`
    height: calc(var(--vh) * 100);
  `};
`;

const Content = styled(Box)``;

export type ScrollableProps = React.ComponentProps<typeof Content>;

const Scrollable = ({ children, ...props }: ScrollableProps) => (
  <Frame>
    <Content {...props}>{children}</Content>
  </Frame>
);

export default Scrollable;
