import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Box from 'components/box';
import Flex from 'components/flex';
import Link from 'components/link';
import withProps from 'hocs/withProps';
import { Trans } from 'i18n';

const components = {
  Box,
  Flex,
  Trans,
  a: withProps(Link, {
    white: true,
  }),
};

type Props = {
  children: React.ReactNode;
};

export const Provider: React.FC<Props> = ({ children }) => {
  return <MDXProvider components={components}>{children}</MDXProvider>;
};

export default MDXRenderer;
