import { PageProps } from 'gatsby';

import Fluid from 'components/fluid';
import SEO from 'components/seo';
import { useTranslation } from 'i18n';

const ProjectTemplate: React.FC<PageProps> = ({ path, pageContext }) => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t('Projects page')} path={path} />

      <Fluid>{pageContext.id}</Fluid>
    </>
  );
};

export default ProjectTemplate;
