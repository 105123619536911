import styled, { CSSProperties, css } from 'styled-components';

import Box from 'components/box';

export type TextProps = {
  size?: 'sm' | 'lg';
  white?: boolean;
  italic?: boolean;
  bold?: boolean;
  fontStyle?: CSSProperties['fontStyle'];
  fontWeight?: CSSProperties['fontWeight'];
  children: React.ReactNode;
};

const Text = styled(Box).attrs((props) => ({
  as: 'span',
  ...props,
}))<TextProps>`
  display: inline-block;
  white-space: pre;
  font-style: ${(props) => props.fontStyle || (props.italic ? 'italic' : 'normal')};
  font-weight: ${(props) => props.fontWeight || (props.bold ? 'bold' : 'normal')};

  ${(props) =>
    props.size === 'sm' &&
    css`
      font-size: 10px;
    `}

  ${(props) =>
    props.size === 'lg' &&
    css`
      font-size: 16px;
    `}

  ${(props) =>
    props.white &&
    css`
      color: var(--text-color-1);
    `}
`;

export default Text;
