import { useEffect } from 'react';
import { PageProps } from 'gatsby';

import Fluid from 'components/fluid';
import SEO from 'components/seo';
import useNavigate from 'hooks/useNavigate';
import { useTranslation } from 'i18n';

const IndexPage: React.FC<PageProps> = ({ path }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('history', { replace: true });
  }, [navigate]);

  return (
    <>
      <SEO title={t('Home page')} path={path} />

      <Fluid />
    </>
  );
};

export default IndexPage;
