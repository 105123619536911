import { useMemo } from 'react';
import { graphql } from 'gatsby';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import mergeWith from 'lodash/mergeWith';
import orderBy from 'lodash/orderBy';

import MDX from 'components/mdx';
import Timeline, { TimelineItemProps } from 'components/timeline';
import { useLocalization } from 'i18n';
import { HistoryItemFragment } from 'typings/graphql';

export const fragment = graphql`
  fragment HistoryItem on MdxConnection {
    group(field: fields___name) {
      nodes {
        fields {
          locale
          isDefault
          category
          name
        }
        frontmatter {
          title
          subtitle
          dateFrom
          dateTo
        }
        body
      }
    }
  }
`;

const mapHistoryItems = (data: HistoryItemFragment, locale: string, defaultLang: string) => {
  return orderBy(
    data.group
      .reduce((result, group) => {
        const defaultItem = find(group.nodes, ['fields.locale', defaultLang]);
        const localeItem = find(group.nodes, ['fields.locale', locale]);
        const item = mergeWith({}, defaultItem, localeItem, (objValue, srcValue) => {
          if (!isObject(objValue) && !isObject(srcValue)) {
            return srcValue || objValue;
          }
        });

        return [...result, item];
      }, [])
      .map(
        ({ frontmatter, body }) =>
          ({
            title: frontmatter.title,
            subtitle: frontmatter.subtitle,
            dateFrom: frontmatter.dateFrom,
            dateTo: frontmatter.dateTo || null,
            children: <MDX>{body}</MDX>,
          } as TimelineItemProps),
      ),
    ['dateTo', 'dateFrom'],
    ['desc', 'desc'],
  );
};

type Props = {
  data: HistoryItemFragment;
};

const History: React.FC<Props> = ({ data }) => {
  const { locale, defaultLang } = useLocalization();
  const items = useMemo(() => mapHistoryItems(data, locale, defaultLang), [data, locale, defaultLang]);

  return <Timeline items={items} />;
};

export default History;
