import { useEffect, useRef } from 'react';
import { Circle, Line, PathDrawingOptions } from 'progressbar.js';
import styled, { css } from 'styled-components';

import Box, { BoxProps } from 'components/box';

type ProgressType = 'circle' | 'line';

const Wrapper = styled(Box)<{ type: ProgressType }>`
  color: var(--text-color-2);

  path:first-child {
    stroke: var(--deep-color);
  }

  path:last-child {
    stroke: var(--main-color);
  }

  ${(props) =>
    props.type === 'line' &&
    css`
      .progressbar-text {
        width: 100%;
        text-align: right;
        top: -14px !important;
      }
    `}
`;

type ProgressProps = {
  value: number;
  type?: ProgressType;
  width?: number;
  options?: PathDrawingOptions;
} & BoxProps;

const Progress: React.FC<ProgressProps> = ({ value, type, width, options, ...props }) => {
  const element = useRef();

  useEffect(() => {
    const progress = new (type === 'circle' ? Circle : Line)(element.current, {
      strokeWidth: width,
      trailWidth: width,
      color: 'var(--text-color-2)',
      step(_, path) {
        // @ts-expect-error
        path.setText(Math.round(path.value() * 100) + '%');
      },
      ...options,
    });

    progress.animate(value / 100);

    return () => {
      progress.destroy();
    };
  }, [value, type, width, options, element]);

  return <Wrapper {...props} type={type} ref={element} />;
};

Progress.defaultProps = {
  type: 'circle',
  width: 7,
};

export default Progress;
