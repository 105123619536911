import { PageProps } from 'gatsby';
import styled from 'styled-components';

import Flex from 'components/flex';
import Fluid from 'components/fluid';
import SectionTitle from 'components/sectionTitle';
import SEO from 'components/seo';
import CompaniesHistory from 'containers/companiesHistory';
import EducationHistory from 'containers/educationHistory';
import { useTranslation } from 'i18n';
import media from 'styles/media';

const Section = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

const Sections = styled(Flex)`
  flex-direction: column;

  ${media.large`
    flex-direction: row-reverse;

    ${Section} {
      margin-right: 16px;

      &:first-child {
        margin-right: 0;
      }
    }
  `};
`;

const HistoryPage: React.FC<PageProps> = ({ path }) => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t('History page')} path={path} />

      <Fluid>
        <Sections>
          <Section>
            <SectionTitle>{t('Work history')}</SectionTitle>
            <CompaniesHistory />
          </Section>
          <Section>
            <SectionTitle>{t('Education')}</SectionTitle>
            <EducationHistory />
          </Section>
        </Sections>
      </Fluid>
    </>
  );
};

export default HistoryPage;
