import { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { LocaleContext } from 'gatsby-theme-i18n';
import i18n from 'i18next';

import { LocalizationConfigQuery } from 'typings/graphql';

export { Trans, useTranslation };
export type { TFunction };

export function useLocalization() {
  const locale: string = useContext(LocaleContext);
  const {
    themeI18N: { defaultLang, prefixDefault, locales, configs },
  } = useStaticQuery<LocalizationConfigQuery>(graphql`
    query LocalizationConfig {
      themeI18N {
        defaultLang
        prefixDefault
        locales
        configs: config {
          code
          name
          langDir
          hrefLang
          localName
          timeFormat
          dateFormat
          dateTimeFormat
        }
      }
    }
  `);
  const config = useMemo(() => configs.find(({ code }) => code === locale)!, [configs, locale]);

  return {
    locale,
    locales: locales.split(' '),
    defaultLang,
    prefixDefault,
    configs,
    config,
  };
}

export default i18n;
