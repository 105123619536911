import { useMemo } from 'react';
import findLast from 'lodash/findLast';
import styled, { css } from 'styled-components';

import Link from 'components/link';
import { useTranslation } from 'i18n';

const MenuListItem = styled.li`
  width: 100%;
  list-style-type: none;
  padding: 8px 28px;
  opacity: 0;
  transition: opacity var(--tr-lg);
`;

const MenuList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0 0 60px;
`;

const ActiveMenu = styled.div`
  width: 200px;
  left: -62px;
  top: 120px;
  overflow: hidden;
  position: absolute;
  opacity: 1;
  font-weight: 500;
  text-transform: uppercase;
  transform: rotate(90deg);
  color: var(--text-color-2);
  transition: opacity var(--tr-md);
`;

const Nav = styled.nav<{ active: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: none;

  ${(props) =>
    props.active &&
    css`
      pointer-events: all;

      ${MenuListItem} {
        opacity: 1;
      }

      ${ActiveMenu} {
        opacity: 0;
      }
    `}
`;

type Props = {
  active: boolean;
  activePath: string;
  onMenuItemClick?: React.MouseEventHandler;
};

const MainMenu: React.FC<Props> = ({ active, activePath, onMenuItemClick }) => {
  const { t } = useTranslation();
  const menuLinks = useMemo(
    () => [
      {
        to: '/',
        children: t('Home page'),
      },
      {
        to: '/blog',
        children: t('Blog page'),
      },
      {
        to: '/projects',
        children: t('Projects page'),
      },
      {
        to: '/speeches',
        children: t('Speeches page'),
      },
      {
        to: '/history',
        children: t('History page'),
      },
      {
        to: '/contact',
        children: t('Contact page'),
      },
    ],
    [t],
  );
  const activeMenu = useMemo(() => findLast(menuLinks, ({ to }) => activePath.includes(to)), [menuLinks, activePath]);

  return (
    <Nav active={active}>
      {activeMenu && <ActiveMenu>{activeMenu.children}</ActiveMenu>}

      <MenuList>
        {menuLinks.map(({ to, children }) => (
          <MenuListItem key={to}>
            <Link to={to} white={to === activeMenu?.to} onClick={onMenuItemClick}>
              {children}
            </Link>
          </MenuListItem>
        ))}
      </MenuList>
    </Nav>
  );
};

export default MainMenu;
