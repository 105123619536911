import { PageProps } from 'gatsby';

import MainLayout from './main';

type Props = {} & Omit<PageProps, 'children'> & React.ComponentProps<typeof MainLayout>;

const Layout: React.FC<Props> = ({ children, ...props }) => {
  return <MainLayout {...props}>{children}</MainLayout>;
};

export default Layout;
