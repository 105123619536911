import 'dayjs/locale/en';
import 'dayjs/locale/ru';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export function diffDate(dateFrom: dayjs.ConfigType, dateTo: dayjs.ConfigType, unit?: dayjs.QUnitType | dayjs.OpUnitType) {
  return dayjs(dateFrom).diff(dateTo, unit);
}

export function diffDateFromNow(date: dayjs.ConfigType, unit?: dayjs.QUnitType | dayjs.OpUnitType) {
  return diffDate(dayjs(), date, unit);
}

export function formatDate(date: dayjs.ConfigType, format?: string, locale?: string) {
  return dayjs(date, { locale }).format(format);
}
